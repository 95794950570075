
export const jobList = [
    {
        id: 0,
        roles: "Software Engineering",
        list: [ 
            "Fullstack Developer", 
            "Backend Developer", 
            "Frontend Developer",
            "DevOps / DevSecOps Engineer", 
            "QA / Test Engineer",
            "Security /Network Engineer",
            "IT Infrastructure"
        ]
    },
    {
        id: 1,
        roles: "Product Management",
        list: [ 
            "Product Owner",
            "Product Design",
            "Product Manager",
            "IT Project Manager",
            "Delivery Manager"
        ]
    },
    {
        id: 2,
        roles: "Marketing & Communications",
        list: [      
            "Account Manager",
            "Digital Business Representative",
            "Social Media Specialist",
            "Account Executive",
            "Brand / Product Management",
            "Partnership Manager",
            "Graphic Designer",
            "Technical Writer"
        ]
    },
    {
        id: 3,
        roles: "Data Science",
        list: [      
            "Database Administrator",
            "Data Engineer",
            "Data Quality",
            "Data Scientist",
            "Data Steawards",
            "Data Analyst"
        ]
    },
    {
        id: 4,
        roles: "Services",
        list: [      
            "Waiters",
            "Supervisor",
            "Crew Floor",
            "Crew Kitchen",
            "Host Live "
        ]
    }
];


export const positionList = [
    {
        id: 0,
        position: "Devsecops",
        experienceLevel: 2,
        jobType: 0,
        educationLevel: 0,
        type: 0,
        roles: "Software Engineering",
        requirements: [
            "At least 2 years experience in the relevant field",
            "Experience with cloud security or other security applications",
            "Experienced in penetration testing to identify security vulnerabilities within applications",
            "Ability to perform security risk analysi",
            "Capable of collaborating with teams effectively",
            "Effective communication skill with ability to influence "
        ]
    },
    {
        id: 1,
        position: "PM IT Infra",
        experienceLevel: 1,
        jobType: 0,
        educationLevel: 2,
        type: 0,
        roles: "Software Engineering",
        requirements: [ 
            "Ex PM IT Infra",
            "Minimum Bachelor degree in Computer Science, Information System, or Information Technology",
            "At least 1-2 years working experience in developer",
            "Leadership, critical thinking and analytical skills",
            "Problem solving, analyze, evaluate and identify solution",
            "Strategy",
            "Effective communication skill with ability to influence",
            "Preferably someone who experience in managing team using agile principles and has knowledge of insurance",
            "Experience using tools JIRA, Microsoft 365 and VPN Global Protect"
        ]
    },
    {
        id: 2,
        position: "Product Owner",
        experienceLevel: 3,
        jobType: 0,
        educationLevel: 2,
        type: 0,
        roles: "Software Engineering",
        requirements: [      
            "Minimum Bachelor degree in Computer Science, Information System, or Information",
            "At least 3 years experience as Product Owner",
            "Experience in AGILE - SCRUM and JIRA",
            "Familiar what business process is, what a process and what an activity is",
            "Understand how to create user stories",
            "Ex developer",
            "Large-scale enterprise system",
            "Leadership, critical thinking and analytical skills",
            "Problem solving, analyze, evaluate and identify solution",
            "Effective communication skill with ability to influence",
            "Product minded oriented that always put our foot in user basis",
            "Preferably someone who experience in managing team using agile principles and has knowledge of insurance"
        ]
    },
    {
        id: 3,
        position: "Data Base Administrator (DBA)",
        experienceLevel: 3,
        jobType: 0,
        educationLevel: 0,
        type: 0,
        roles: "Software Engineering",
        requirements: [      
           "Minimum of 3 years of experience in database administration",
            "Proficiency in multiple database management systems such as Oracle, SQL Server, MySQL, etc.",
            "Strong understanding of database architecture, design, and implementation",
            "Experience with data backup and recovery",
            "Experience with performance tuning and optimization",
            "Ability to troubleshoot and resolve database issues",
            "Excellent communication and collaboration skills",
            "Strong attention to detail and problem-solving skills",
            "Ability to work independently and as part of a team",
            "Familiarity with database security best practices"
        ]
    },
    {
        id: 4,
        position: "Dev Lead PHP",
        experienceLevel: 1,
        jobType: 0,
        educationLevel: 2,
        type: 0,
        roles: "Software Engineering",
        requirements: [      
            "At least 1-3 years working experience in delivery of technology solution and/or large-scale enterprise system", 
            "Bachelor’s degree in computer science or a similar field.",
            "Knowledge of PHP web frameworks including Yii, Laravel, and CodeIgniter.",
            "Knowledge of front-end technologies including CSS3, JavaScript, and HTML5.",
            "Understanding of object-oriented PHP programming.",
            "Preferably someone who experience in managing team using agile principles and has knowledge of insurance",
            "Previous experience creating scalable applications.",
            "Proficient with code versioning tools including Git, Mercurial, CVS, and SVN.",
            "Familiarity with SQL/NoSQL databases.",
            "Ability to project manage.",
            "Good problem-solving skills."
        ]
    },
    {
        id: 5,
        position: "Host Live Streaming",
        experienceLevel: 0,
        jobType: 0,
        educationLevel: 0,
        type: 0,
        roles: "Services",
        requirements: [      
            "Pendidikan minimal SMA atau setara.", 
            "⁠Pengalaman sebagai host live streaming diutamakan.",
            "Kemampuan berbicara yang baik dan memiliki daya tarik di depan kamera.",
            "Keterampilan dalam membangun interaksi dengan audiens.",
            "⁠Bersedia bekerja shift malam.",
        ]
    },
];


